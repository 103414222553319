.App {
  text-align: center;
}

.App-logo {
  height: 200px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding-top: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-button {
  cursor: pointer;
  transition: transform 0.05s;
}

.logo-button:hover {
  transform: scale(0.95);
}

.reset-button {
  position: absolute;
  top: 30px;
  right: 20px;
  font-size: 0.8rem;
}

.quiz-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quiz-form input {
  margin: 10px;
  min-height: 35px;
  min-width: 200px;
}

button {
  margin: 20px;
  min-height: 45px;
  height: 100%;
  min-width: 150px;
  text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
  button {
    height: 55px;
  }
}

.radio-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .radio-container {
    flex-direction: column;
  }
}

.answer-item button {
  min-width: 200px;
  font-size: 1.3rem
}

.answer-item {
  margin: 10px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .answer-item {
    margin: 10px 0;
  }
}

.result-answer {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  font-size: 1.4rem;
}

.results {
  text-align: start;
  max-width: 60%;
}

@media only screen and (max-width: 768px) {
  .results {
    max-width: 95%;
  }
}


.query-input {
  display: flex;
  flex-direction: row;
}


@media only screen and (max-width: 768px) {
  .query-input {
    flex-direction: column;
  }
}

.query-input input {
  margin: 0;
  min-width: 200px;
}
.query-input button {
  margin: 0;
}